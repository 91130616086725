import { Pipe, PipeTransform } from '@angular/core';
import { startsWith } from 'lodash';

@Pipe({
  name: 'firstLetterCapital',
  standalone: true
})
export class FirstLetterCapitalPipe implements PipeTransform {

  transform( word: string ): unknown {
    if (!word) return word;

    if( word.startsWith('¿') || word.startsWith('¡') ){
      return word[0] + word[1].toUpperCase() + word.substring(2).toLowerCase();
    }

    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

}
